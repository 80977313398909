import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { ReactNode, useEffect, useState } from "react";
import "./topHeader.css";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PersonIcon from "@material-ui/icons/Person";
import MenuIcon from "@material-ui/icons/Menu";
import Sidemenu from "../SideMenu/Sidemenu";
import { Link, useHistory } from "react-router-dom";
import { auth } from "../../firebaseConfig";
import { useStateValue } from "../Redux/StateProvider";
import { Button } from "@material-ui/core";
import PersonModal from "./PersonModal";

// interface UserType {
//  user:Array<number|string>
// }

function Topheader(props: any) {
  const [{ user,basket }] = useStateValue();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [hover, setHover] = useState(false);
  var [displayname, setDisplayame] = useState("");

  useEffect(() => {
    setDisplayame(user?.displayName);
  }, [user]);

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const Signin = () => {
    if (user) {
      auth.signOut();
    } else history.push("/account");
  };

  console.log(user);
  return (
    <div className="topheader">
      <IonGrid className="tohHeader_grid">
        <IonRow className="row_logo_t">
          <IonCol size="5" className="logo_top_col">
            <Link to="/">
              <img
                className="logo1"
                src="https://res.cloudinary.com/dpysmqax5/image/upload/v1615074337/logo_white_solid__copy_dqpf6l.png"
                alt="logo1"
              />
            </Link>
          </IonCol>
        </IonRow>
        <IonRow className="sidebar_top_row ">
          <IonCol size="4" className="sidebar_top_col">
            <ShoppingCartIcon
              fontSize="large"
              style={{ color: "whitesmoke" }}
              className="tocursor shoping_icon"
              onClick={() => history.push("/cart")}
            />
           <div className={basket.length > 0 ? "basketCount" : ""}> {basket.length > 0 ? basket.length : ""} </div>
           
            <div
              className="profile_btn"
              onMouseEnter={onHover}
              onMouseLeave={onLeave}
              role="button"
            >
              <PersonIcon
                className="tocursor person_icon"
                fontSize="large"
                style={{ color: "whitesmoke" }}
              />
              {user ? (
                <IonCol className="dspname" size="3">
                  Hi, {displayname}
                </IonCol>
              ) : (
                ""
              )}

              <PersonModal hover={hover} Signin={Signin} user={user} displayName={displayname}/>
            </div>
            <div onClick={() => setOpen(!open)}>
              <MenuIcon
                className="tocursor menu_icon"
                fontSize="large"
                style={{ color: "whitesmoke" }}
              />
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      {open && <Sidemenu open={open} setOpen={setOpen} />}
    </div>
  );
}

export default Topheader;
