import React from "react";
import "./subtotal.css";
import * as CurrencyFormat from 'react-currency-format';
import { useStateValue } from "../../Redux/StateProvider";
import { getBasketTotal } from "../../Redux/reducer";
import { useHistory } from "react-router-dom";

function Subtotal() {
  let history = useHistory();
  const [{ basket, user }, diaptch] = useStateValue();

  return (
    <div className="subtotal">
      {!user ? (
        history.push("/login")
      ) : (
        <button onClick={(e) => history.push("/payment")}>
          Proceed to checkout
        </button>
      )}

      <CurrencyFormat
        renderText={(value: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined) => (
          <>
            <p>
              Subtotal ({basket?.length} items):<strong>{value}</strong>
            </p>
            
          </>
        )}
        decimalScale={2}
        value={getBasketTotal(basket)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    </div>
  );
}

export default Subtotal;