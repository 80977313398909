  
import firebase from "firebase";


const firebaseConfig = {
  apiKey: "AIzaSyAa0lr9dWP9dRK4Cls5Mcq7x221qJ6eTgQ",
  authDomain: "scratchnest-ff76c.firebaseapp.com",
  projectId: "scratchnest-ff76c",
  storageBucket: "scratchnest-ff76c.appspot.com",
  messagingSenderId: "426221534269",
  appId: "1:426221534269:web:ec9b657c3fe6e366403e54",
  measurementId: "G-KQ8J3Z9TQK",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();

export { db, auth };


export default firebaseConfig;