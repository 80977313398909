import React from 'react'
import "./shop.css"
import { useStateValue } from "../Redux/StateProvider";


function Product({  ...props }) {
  const [{ basket, user }, dispatch] = useStateValue();

  const addToBasket = () => {
    //Add item to basket
    dispatch({
      type: "ADD_TO_BASKET",
      item: {
        title: props.title,
        image: props.image,
        price: props.price,
      },
    });
  };
    return (
        
              <div className="product">
      <div className="product__info">
        <p>{props.title}</p>
        <p className="product__price">
          <small>$</small>
          <strong>{props.price}</strong>
        </p>
        
      </div>

      <img src={props.image} alt="" />

      <button onClick={addToBasket}>Add to Basket</button>
    </div>


    
    )
}

export default Product
