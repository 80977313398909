import { IonText } from "@ionic/react";
import React from "react";
import Product from "./CommonProduct";
import "./shop.css";

function Shop() {
  return (
    <>
      <div className="home_row">
        <Product
          title={
            <IonText className="ir product_shop">
              Integrated RFID Reader <br />1 Port Reader <br />
              SN-IR 101
            </IonText>
          }
          image="https://res.cloudinary.com/dpysmqax5/image/upload/v1615709108/A6_1_ao6ynz.jpg"
          price="14.5"
        />
        <Product
          title={
            <IonText className="ir product_shop">
              Integrated RFID Reader <br />2 Port Reader <br />
              SN-IR 201
            </IonText>
          }
          image="https://res.cloudinary.com/dpysmqax5/image/upload/v1615274631/M6_d0z0ac.jpg"
          price="14.5"
        />
      </div>
      <div className="home_row">
        <Product
          title={
            <IonText className="ir product_shop">
              Fixed RFID Reader <br />2 Port Reader <br />
              SN-FR 201
            </IonText>
          }
          image="https://res.cloudinary.com/dpysmqax5/image/upload/v1616702029/2_port_fixed_reader_gte5zd.jpg"
          price="14.5"
        />
        <Product
          title={
            <IonText className="ir product_shop">
              Fixed RFID Reader <br />4 Port Reader <br />
              SN-FR 401
            </IonText>
          }
          image="https://res.cloudinary.com/dpysmqax5/image/upload/v1616702945/4_port_fixedreader_f4lvut.jpg"
          price="14.5"
        />
      </div>
      <div className="home_row">
        <Product
          title={
            <IonText className="ir product_shop">
              Linear Polarized Antenna
              <br />9 dBi
              <br />
              SN-LA 101
            </IonText>
          }
          image="https://res.cloudinary.com/dpysmqax5/image/upload/v1619040017/anteena_linear_0_z3frdj.png"
          price="14.5"
        />
        <Product
          title={
            <IonText className="ir product_shop">
              Circular Polarized Antenna
              <br />9 dBi
              <br />
              SN-CA 101
            </IonText>
          }
          image="https://res.cloudinary.com/dpysmqax5/image/upload/v1619040017/anteena_linear_0_z3frdj.png"
          price="14.5"
        />
      </div>
      <div className="home_row">
        <Product
          title={
            <IonText className="ir product_shop">
            Tracking Beacon
             
              <br />
              SN - TB 101
            </IonText>
          }
          image="https://res.cloudinary.com/dpysmqax5/image/upload/v1619040017/anteena_linear_0_z3frdj.png"
          price="14.5"
        />
        <Product
          title={
            <IonText className="ir product_shop">
             Data Logger

            
              <br />
              SN - DL 101

            </IonText>
          }
          image="https://res.cloudinary.com/dpysmqax5/image/upload/v1619040017/anteena_linear_0_z3frdj.png"
          price="14.5"
        />
      </div>
    </>
  );
}

export default Shop;
