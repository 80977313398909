import React, { useEffect } from "react";
import "./checkoutproduct.css";
import { useStateValue } from "../Redux/StateProvider";
import Footer from "../Footer/Footer";
import axios from "../../axios";


function CheckoutProduct({...props}) {
  const [{ basket, user }, dispatch] = useStateValue();

  let data = {
    name: user?.email,
    email: user?.email,
    title:props.title,
    price:props.price,
    image:props.image,
  };

  useEffect(() => {
    const addcart = async () => {
      const res = await axios.post("/cartitems", data)
        .then((res) => {
          alert("added to db");
        console.log(res.data)
        })
        .catch((error) => {
          alert(`An error occured ${error}`);
          console.log(error)
        });
    }
  
    addcart()

    const getcartdata = async () =>{
      const res = await axios.get("//cart/sync",{params: {price:props.price}})
      console.log(res.data.title)
    }
    getcartdata()
   
  }, [basket]);

  const removeFromBasket = () => {
    // remove the item from the basket
    dispatch({
      type: "REMOVE_FROM_BASKET",
      id: props.id,
    });
  };
  // console.log("Ratingss >>>" + ratings);

  return (
    <div className="checkoutProduct">
      <img className="checkoutProduct__image" src={props.image} />

      <div className="checkoutProduct__info">
        <p className="checkoutProduct__title">{props.title}</p>
        <p className="checkoutProduct__price">
          <small>$</small>
          <strong>{props.price}</strong>
        </p>
      
        {!props.hideButton && (
          <button onClick={removeFromBasket}>Remove from Basket</button>
        )}
      </div>
   
    </div>
  );
}

export default CheckoutProduct;