import React, { useEffect } from "react";
import axios from "../../axios";
import { useStateValue } from "../Redux/StateProvider";
import "./Checkout.css";
import CheckoutProduct from "./CheckoutProduct";
import Subtotal from "./Subtotal/Subtotal";

function Checkout() {
  const [{ basket, user }] = useStateValue();




  return (
    <div className="checkout">
      <div className="checkout_left">
        <img
          className="checkout_ad"
          src="https://res.cloudinary.com/dpysmqax5/image/upload/v1617630530/tab_gif_yotsj7.gif"
          alt=""
        />
        {basket?.length === 0 ? (
          <div>
            <h2>
              Your Cart is Empty. Please add some items to continue Shopping.
            </h2>
          </div>
        ) : (
          <div>
            <h2 className="checkout_title"> Your Shopping cart</h2>
            {basket?.map((item: { id: any; title: any; image: any; price: any; rating: any; }) => (
              <CheckoutProduct
                id={item.id}
                title={item.title}
                image={item.image}
                price={item.price}
                rating={item.rating}
                key={item.id}
              />
            ))}
          </div>
        )}
      </div>
      {basket?.length > 0 && (
        <div className="checkout_right">
          <h1>Subtotal</h1>
          <Subtotal />
        </div>
      )}
    </div>
  );
}

export default Checkout;